<template>
	<div>
		<div class="container">
			<div class="header">
				<a @click="back"><img src="../assets/images/back.png" alt=""></a> 填写信息</div>
			<div class="blian22"></div>
		</div>
		<div class="recordcont">
			<div class="title"><em></em>填写所购图书信息</div>
			<div class="recordedit" v-for="(item , index) in buyArr" :key="index">
				<img src="../assets/images/del.png" class="delImg" @click="delInfo(index)" v-if="index>0"/>
				<div class="recordcont-mail">
					<p style="margin-bottom:1.5rem;">图书
						<span @click="addbookInfo(1,index)" v-if="!item.bookName" style="color:#ccc;">          
            例:点播/四年级/英语/冀教版/下册
            <img src="../assets/images/down_on1.png" alt="">
          </span>
						<span @click="addbookInfo(1,index)" v-else style="color:#232323;">        
            {{item.bookName}}/{{item.gradeName}}/{{item.kmName}}/{{item.bmName}}/{{item.cbName}}
            <img src="../assets/images/down_on1.png" alt="">
          </span>
					</p>
					<p>金额
						<span>
            <input type="number" v-model="item.money" placeholder="">元
          </span>
					</p>
				</div>
			</div>
			<div class="addbooks" @click="addBookNext">+添加下一本书</div>
		</div>
		<div class="recordcont ">
			<div class="title"><em></em>上传购书凭证图片</div>
			<div class="prompt">（正规书店购书小票和所购图书封面照片,如上传错误长按删除)</div>
			<div class="recordcont-evidence">
				<span>
	        <img src="../assets/images/upload.png" alt="" class="vertical">
	      </span>
				<input type="file" @change="uploadBefore" name="file" multiple style="width: 5rem; height: 5rem;position: absolute;left: 0;opacity: 0;" />

				<img :src="basUrl+item" alt="" v-for="(item,index) in imgArr" :key="index" @touchstart.prevent="showDeleteButton(index)" @touchend.prevent="clearLoop(index)">
			</div>
		</div>
		<div class="recordcont ">
			<div class="title"><em></em>填写邮寄信
				<a class="color-3674FF f-r" @click="goAddress">选择已有地址</a>
			</div>
			<div class="recordcont-mail" v-if="address.recName">
				<p style="margin-bottom:1.5rem;">姓名
					<span style="color:#232323;">
						{{address.recName}}
        </span>
				</p>
				<p style="margin-bottom:1.5rem;">手机号
					<span style="color:#232323;">
						{{address.recPhone}}
        </span>
				</p>
				<p>地址
					<span style="color:#232323;">        
          {{address.province}}{{address.city}}{{address.region}}
        </span>
				</p>
			</div>
		</div>
		<div class="recordcont ">
			<div class="title"><em></em>填写您想要的赠书信息</div>
			<div class="recordedit" v-for="(item , index) in giveArr" :key="index">
				<img src="../assets/images/del.png" class="delImg" @click="delz(index)" v-if="index>0"/>
				<div class="recordcont-mail">
					<p>图书
						<span @click="addbookInfo(2,index)" v-if="!item.bookName" style="color:#ccc;">          
            例:点播/四年级/英语/冀教版/下册
            <img src="../assets/images/down_on1.png" alt="">
          </span>
						<span @click="addbookInfo(2,index)" v-else style="color:#232323;">        
             {{item.bookName}}/{{item.gradeName}}/{{item.kmName}}/{{item.bmName}}/{{item.cbName}}
            <img src="../assets/images/down_on1.png" alt="">
          </span>
					</p>
				</div>
			</div>
			<div class="addbooks" @click="addBookNext2">+添加下一本书</div>
		</div>
		<div class="blian52"></div>
		<!--底部按钮 begin-->
		<div class="cont_foot_btn">
			<a @click="submit">提交审核</a>
		</div>
		<!-- 图书信息 -->
		<BookInfo @hidden="hiddenShow" v-show="showbookInfo" ref="bookInfo"></BookInfo>
	</div>
</template>
<script>
	import { uploadPic, joinActivity } from "@/assets/js/api";
	import BookInfo from '@/components/V-BookInfo.vue'
	import { Toast,MessageBox } from 'mint-ui';
	export default {
		name: "myrecordedit",
		components: {
			BookInfo,
		},
		data() {
			return {
				imgArr: [],
				buyArr: [{}], //购买图书数据，
				giveArr: [{}], //赠书信息
				haveIndex: 0,
				Loop:null,
				buyOrHave: '', //1购买图书 2赠书信息

				showAddress: false,
				address: {},
				// 图书信息
				showbookInfo: false,
				bookInfo: {
					bookName: "",
					gradeName: ""
				}
			}
		},
		methods: {
			showDeleteButton(index) {
				clearInterval(this.Loop); //再次清空定时器，防止重复注册定时器
				let that = this;
				this.Loop = setTimeout(function() {
					MessageBox.confirm('是否移除图片?').then(action => {
                      that.imgArr.splice(index,1)
					});
				}, 1000);
			},
			clearLoop() {
				clearInterval(this.Loop);
			},
			//删除购书
			delInfo(index){
				MessageBox.confirm('是否移除该信息?').then(action => {
                  this.buyArr.splice(index,1)
				});
			},
			//删除赠书
			delz(index){
				MessageBox.confirm('是否移除该信息?').then(action => {
                  this.giveArr.splice(index,1)
				});
			},
			back() {
				this.$router.go('-1')
				localStorage.setItem('params', '')
			},
			//去选择地址
			goAddress() {
				let params = {
					sendInfo: this.giveArr,
					info: this.buyArr,
					receipt: this.imgArr,
				}
				localStorage.setItem('params', JSON.stringify(params))
				this.$router.push({
					path: "./myaddress",
					query: {
						type: 1,
					}
				});
			},

			addBookNext: function() {
				let obj = {};
				this.buyArr.push(obj)
			},
			addBookNext2: function() {
				let obj = {};
				this.giveArr.push(obj)
			},
			addbookInfo: function(type, index) {
				this.haveIndex = index;
				this.buyOrHave = type;
				this.showbookInfo = true;
			},
			hiddenShow: function(data) {
				if(this.buyOrHave == 1) {
					this.buyArr[this.haveIndex] = data;
				} else {
					this.giveArr[this.haveIndex] = data;
				}
				this.showbookInfo = false;
			},
			// 文件上传
			uploadBefore(e) {
				e.preventDefault();
				let files = event.target.files;
				for(var i = 0; i < files.length; i++) {
					let formData = new FormData();
					formData.append('dir', 'other');
					formData.append('token', localStorage.getItem('token'));
//					formData.append('token', '38f09719-5587-4738-ac52-26366b67d36b');
					formData.append('pic', files[i]);
					uploadPic(formData).then(res => {
						if(res.code == 0) {
							this.imgArr.push(res.picPath)
						}
					});
				}
			},
			//提交审核
			submit() {
				let money = 0;
				let info = [];
				let sendInfo = [];
				for(var i = 0; i < this.buyArr.length; i++) {
					if(JSON.stringify(this.buyArr[i]) != "{}") {
						money += Number(this.buyArr[i].money)
						info.push(this.buyArr[i].bookName + '/' + this.buyArr[i].gradeName + '/' + this.buyArr[i].kmName + '/' + this.buyArr[i].bmName + '/' + this.buyArr[i].cbName);
					}
				}
				for(var i = 0; i < this.giveArr.length; i++) {
					if(JSON.stringify(this.giveArr[i]) != "{}") {
						sendInfo.push(this.giveArr[i].bookName + '/' + this.giveArr[i].gradeName + '/' + this.giveArr[i].kmName + '/' + this.giveArr[i].bmName + '/' + this.giveArr[i].cbName);
					}
				}
				let params = {
					sendInfo: sendInfo.join(','),
					info: info.join(','),
					money: money,
					receipt: this.imgArr.join(','),
					receiveName: this.address.recName,
					receivePhone: this.address.recPhone,
					receiveAddr: this.address.province + this.address.city + this.address.region
				}

				joinActivity(params).then(res => {
					if(res.code == 0) {
						this.$router.go(-2)
						Toast({
							message: '报名成功',
							position: 'middle',
							duration: 1000
						});
						localStorage.setItem('params', '')
					}
				});
				console.log(params)
			}
		},
		activated() {

		},
		mounted() {
			if(localStorage.getItem('params')) {
				let p = JSON.parse(localStorage.getItem('params'))
				this.giveArr = p.sendInfo;
				this.buyArr = p.info;
				this.imgArr = p.receipt;
			}
			if(this.$route.query.info) {
				this.address = JSON.parse(this.$route.query.info)
			}
		}
	};
</script>