import { render, staticRenderFns } from "./V-BookInfo.vue?vue&type=template&id=4c517d70&scoped=true&"
import script from "./V-BookInfo.vue?vue&type=script&lang=js&"
export * from "./V-BookInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c517d70",
  null
  
)

export default component.exports